import { render, staticRenderFns } from "./Broadsheet.vue?vue&type=template&id=236a9812&scoped=true&"
import script from "./Broadsheet.vue?vue&type=script&lang=js&"
export * from "./Broadsheet.vue?vue&type=script&lang=js&"
import style0 from "./Broadsheet.vue?vue&type=style&index=0&id=236a9812&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "236a9812",
  null
  
)

export default component.exports