<template>
  <v-container fluid>
    <PageHeader>
      <span slot="courses" class="font-weight-light">BROADSHEET </span>
    </PageHeader>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-form ref="courseHeader">
              <v-row>
                <v-col cols="12" lg="4">
                  <v-autocomplete
                    @change="courseDetails"
                    :loading="loadProgram"
                    v-model="program"
                    :items="itemsProgram"
                    @keyup="searchProgram"
                    clearable
                    class="rounded-0"
                    hide-details="auto"
                    hide-selected
                    item-text="program_name_certificate"
                    item-value="id"
                    label="Search Program"
                    outlined
                    :rules="[(v) => !!v || 'Program is required...']"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title v-if="loadProgram">
                          Searching
                          <strong>Program...</strong>
                        </v-list-item-title>
                        <v-list-item-title v-else>
                          No
                          <strong>Program Found...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-select
                    :menu-props="{ down: true, offsetY: true }"
                    :items="basket_campus"
                    item-value="id"
                    class="rounded-0"
                    item-text="campus_name"
                    @click="fetchCampus"
                    :loading="iscampusLoaded"
                    hide-details="auto"
                    outlined
                    label="Campus"
                    v-model="campus"
                    :rules="[(v) => !!v || 'Campus is required...']"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Campus...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template></v-select
                  >
                </v-col>
                <v-col>
                  <v-select
                    @click="fetchSession"
                    :menu-props="{ down: true, offsetY: true }"
                    class="rounded-0"
                    :items="basket_session"
                    item-text="session"
                    item-value="id"
                    :loading="issessionLoaded"
                    outlined
                    label="Session"
                    v-model="selectedSession"
                    hide-details="auto"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Sessions...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" :lg="ifIntake ? 3 : 4">
                  <v-select
                    :menu-props="{ down: true, offsetY: true }"
                    :loading="iscalendarLoaded"
                    class="rounded-0"
                    v-model="calendar"
                    :items="basket_calendar"
                    :item-text="
                      (item) =>
                        `${item.academic_calendar} ${
                          item.current ? `\t Current` : ' '
                        }`
                    "
                    item-value="id"
                    outlined
                    label="Calendar"
                    @click="fetchCalendar"
                    hide-details="auto"
                    :rules="[(v) => !!v || 'Calendar is required...']"
                  >
                    <template v-slot:selection="{ item }">
                      {{ item.academic_calendar }}
                      <b>{{ `${item.current ? "\t  Current" : ""} ` }}</b>
                    </template>
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Calendar(s)...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" :lg="ifIntake ? 3 : 4">
                  <v-select
                    :menu-props="{ down: true, offsetY: true }"
                    :loading="issemesterLoaded"
                    @click="fetchSemester"
                    v-model="semester"
                    :items="basket_semester"
                    item-value="id"
                    class="rounded-0"
                    item-text="semester"
                    outlined
                    label="Semester"
                    hide-details="auto"
                    hide-selected
                    :rules="[(v) => !!v || 'Semester is required...']"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Semesters...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" :lg="ifIntake ? 3 : 4">
                  <v-select
                    :menu-props="{ down: true, offsetY: true }"
                    :loading="islevelLoaded"
                    :disabled="loadedProgram"
                    class="rounded-0"
                    :items="basket_level"
                    item-text="level"
                    item-value="id"
                    @change="selectedLevel"
                    outlined
                    label="Level"
                    hide-details="auto"
                    v-model="level"
                    hide-selected
                    :rules="[(v) => !!v || 'Calendar is required...']"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Levels...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
                <v-col
                  cols="12"
                  :lg="ifIntake ? 3 : 4"
                  v-if="ifIntake"
                  transition="slide-x=reverse-transition"
                >
                  <v-select
                    class="rounded-0"
                    :menu-props="{ down: true, offsetY: true }"
                    :loading="isintakeLoaded"
                    :items="basket_intake"
                    item-text="intake"
                    item-value="id"
                    outlined
                    label="Intake"
                    v-model="intake"
                    hide-details="auto"
                    hide-selected
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Intakes...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-row align="center" justify="center">
              <v-col cols="12" lg="4">
                <v-btn
                  v-if="
                    getters_abilities.includes('download_broadsheet_access')
                  "
                  height="50"
                  :disabled="disabled"
                  class="title"
                  block
                  color="primary"
                  @click="downloadBroadsheetBtn"
                >
                  DOWNLOAD EXCEL
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-overlay :absolute="absolute" :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-col>
    </v-row>
    <v-overlay :absolute="absolute" :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <Error v-if="deleteResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Error>
  </v-container>
</template>
<script>
  import PageHeader from "@/components/slots/PageHeader";
  import { getCurrentInstance, reactive, ref, toRefs, watch } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import debounce from "@/debounce/debounce";
  import Error from "@/components/Error/Error";

  export default {
    components: { PageHeader, Error },
    setup() {
      const vm = getCurrentInstance();
      const {
        getHodDeptProg,
        getSemester,
        getCalendar,
        getLevel,
        getIntake,
        getCampus,
        getSession,
        downloadBroadSheet,
        signOut,
      } = useActions([
        "getHodDeptProg",
        "getSemester",
        "getCalendar",
        "getLevel",
        "getIntake",
        "getSession",
        "getCampus",
        "getProgramDetails",
        "saveMountedCourses",
        "ifheaderExists",
        "getCampus",
        "getSession",
        "downloadBroadSheet",
        "signOut",
      ]);

      const {
        getters_hoddeptprog,
        getters_semester,
        getters_calendar,
        getters_level,
        getters_intake,
        getters_campus,
        getters_course_header_campus,
        getters_session,
        getters_abilities,
        user,
      } = useGetters([
        "getters_courses",
        "getters_hoddeptprog",
        "getters_semester",
        "getters_calendar",
        "getters_level",
        "getters_intake",
        "getters_program_details",
        "getters_header_exists",
        "getters_campus",
        "getters_course_header_campus",
        "getters_session",
        "getters_abilities",
        "user",
      ]);

      const courseHeader = reactive({
        overlay: false,
        absolute: true,
        get_program_info: {},
        iscalendarLoaded: false,
        issemesterLoaded: false,
        islevelLoaded: false,
        isintakeLoaded: false,
        iscampusLoaded: false,
        issessionLoaded: false,
        loadedLevel: false,
        loadedProgram: true,
        program: "",
        calendar: "",
        semester: "",
        level: "",
        intake: "",
        campus: "",
        selectedSession: "",
        basket_calendar: [],
        basket_semester: [],
        basket_level: [],
        basket_intake: [],
        basket_campus: [],
        basket_lecturers: [],
        basket_session: [],
        ifIntake: false,
        itemsProgram: [],
        loadProgram: false,
        disabled: true,
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        deleteResponse: false,
      });

      const {
        overlay,
        absolute,
        msgHeader,
        get_program_info,
        msgBody,
        msgIcon,
        deleteResponse,
        loadedProgram,
        itemsProgram,
        loadProgram,
        basket_calendar,
        basket_semester,
        basket_level,
        basket_campus,
        basket_intake,
        basket_session,
        ifIntake,
        program,
        calendar,
        semester,
        campus,
        level,
        intake,
        selectedSession,
        iscalendarLoaded,
        issemesterLoaded,
        islevelLoaded,
        isintakeLoaded,
        iscampusLoaded,
        issessionLoaded,
        disabled,
      } = toRefs(courseHeader);

      const dialog = ref(false);
      const courseheaderFeedback = ref(false);
      const courseheaderfeedbackInfo = ref("");
      const assigned = ref(false);
      const header = ref([]);

      watch(
        () => [
          calendar.value,
          semester.value,
          level.value,
          intake.value,
          program.value,
          campus.value,
          selectedSession.value,
        ],
        () => {
          if (
            calendar.value &&
            semester.value &&
            level.value &&
            intake.value &&
            program.value &&
            campus.value &&
            selectedSession.value
          ) {
            header.value = {
              calendar_id: calendar.value,
              semester_id: semester.value,
              level_id: level.value,
              intake_id: intake.value,
              program_id: program.value,
              campus_id: campus.value,
              session_id: selectedSession.value,
            };
            disabled.value = false;
          } else {
            disabled.value = true;
          }
        }
      );

      const searchProgram = debounce(() => {
        if (itemsProgram.value.length > 0) return;
        loadProgram.value = true;

        getHodDeptProg()
          .then(() => {
            itemsProgram.value = getters_hoddeptprog.value;
          })
          .then(() => (loadProgram.value = false));
      });

      const fetchCalendar = () => {
        iscalendarLoaded.value = true;
        if (getters_calendar.value.length > 0) {
          iscalendarLoaded.value = false;
          basket_calendar.value = getters_calendar.value;
          return;
        }
        getCalendar().then(() => {
          basket_calendar.value = getters_calendar.value;
          iscalendarLoaded.value = false;
        });
      };

      const fetchIntake = () => {
        isintakeLoaded.value = true;
        if (getters_intake.value.length > 0) {
          basket_intake.value = getters_intake.value;
          isintakeLoaded.value = false;
          return;
        }

        getIntake();
        basket_intake.value = getters_intake.value;
        isintakeLoaded.value = false;
      };

      fetchIntake();

      const fetchSemester = () => {
        issemesterLoaded.value = true;
        if (getters_semester.value.length > 0) {
          basket_semester.value = getters_semester.value;
          issemesterLoaded.value = false;
          return;
        }

        getSemester().then(() => {
          basket_semester.value = getters_semester.value;
          issemesterLoaded.value = false;
        });
      };

      const fetchCampus = () => {
        iscampusLoaded.value = true;
        /* if (getters_campus.value.length > 0) {
                                    				basket_campus.value = getters_campus.value;
                                    				iscampusLoaded.value = false;
                                    				return;
                                    			} */

        getCampus().then(() => {
          if (getters_abilities.value.includes("all_campus_access")) {
            basket_campus.value = getters_campus.value;
          } else {
            basket_campus.value = getters_campus.value.filter(
              (campus) => campus.id === parseInt(user.value.campus[0].id)
            );
          }
          iscampusLoaded.value = false;
        });
      };

      const fetchSession = () => {
        issessionLoaded.value = true;
        if (getters_session.value.length > 0) {
          basket_session.value = getters_session.value;
          issessionLoaded.value = false;
          return;
        }

        getSession().then(() => {
          basket_session.value = getters_session.value;
          issessionLoaded.value = false;
        });
      };

      const selectedLevel = (level) => {
        intake.value = null;
        var get_level = getters_level.value.find((item) => item.id === level);
        if (get_program_info.value.program_state && get_level.level === 300) {
          basket_intake.value = getters_intake.value.filter(
            (item) => item.intake.toLowerCase() != "all"
          );
          ifIntake.value = true;
        } else if (
          !get_program_info.value.program_state &&
          get_level.level === 300
        ) {
          basket_intake.value = getters_intake.value.filter(
            (item) => item.intake.toLowerCase() == "all"
          );
          ifIntake.value = false;
          intake.value = basket_intake.value[0].id;
        } else if (get_level.level === 200 || get_level.level === 400) {
          ifIntake.value = false;
          basket_intake.value = getters_intake.value.filter(
            (item) => item.intake.toLowerCase() === "all"
          );
          intake.value = basket_intake.value[0].id;
        } else {
          ifIntake.value = true;
          basket_intake.value = getters_intake.value.filter(
            (item) => item.intake.toLowerCase() !== "all"
          );
        }
      };

      const courseDetails = (i) => {
        //	getProgramDetails(i).finally(() => {
        if (!i) return;
        islevelLoaded.value = true;
        loadedProgram.value = true;
        ifIntake.value = false;
        intake.value = "";
        level.value = "";
        getLevel().finally(() => {
          basket_level.value = getters_level.value;
          get_program_info.value = getters_hoddeptprog.value.find(
            (item) => item.id === i
          );

          if (get_program_info.value.program_state) {
            basket_level.value = getters_level.value.filter(
              (item) => item.level >= 300
            );
          } else {
            basket_level.value = getters_level.value;
          }
          islevelLoaded.value = false;
        });

        loadedProgram.value = false;
        //});
      };

      const downloadBroadsheetBtn = () => {
        deleteResponse.value = false;
        overlay.value = true;
        downloadBroadSheet(header.value)
          .then(() => (overlay.value = false))
          .catch((e) => {
            msgIcon.value = "mdi-close-circle";
            deleteResponse.value = true;
            msgHeader.value = "Error";
            overlay.value = false;
            if (e.response.status === 422) {
              msgBody.value = "Header Information Does Not Exists";
            } else if (e.response.status === 403) {
              msgBody.value = "This Action Is Unauthorized";
            } else if (e.response.status === 423) {
              msgBody.value = e.response.data.message;
              signOut().then(() => {
                vm.proxy.$router.replace({
                  name: "Login",
                });
              });
            }
          });
      };

      return {
        ...toRefs(courseHeader),
        fetchCalendar,
        courseDetails,
        fetchIntake,
        fetchSemester,
        fetchCampus,
        selectedLevel,
        absolute,
        overlay,
        getters_course_header_campus,
        courseheaderFeedback,
        searchProgram,
        fetchSession,
        dialog,
        courseheaderfeedbackInfo,
        assigned,
        selectedSession,
        downloadBroadsheetBtn,
        getters_abilities,
      };
    },
  };
</script>
<style scoped>
  .border:hover {
    border-left: 5px solid #1976d2 !important;
    cursor: pointer !important;
  }
</style>
